.toggle-group {
  width: 100%;
  &.first .toggle-header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  &:not(.first) .toggle-header {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .toggle-header {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 8px;
    border: 1px solid #d5d5d5;
    user-select: none;

    .toggle-text {
      flex: 1;
    }

    .toggle-actions {
      display: flex;
      align-items: center;
      gap: 5px;
      i {
        font-size: 20px;
        margin: 0 5px;
      }
    }
  }

  .toggle-content {
    padding: 8px;
    border: 1px solid #fff;
    background-color: #fff;
    * {
      color: #333333;
    }
  }
}
